import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
    static values = { payload: String, editmode: Boolean, id: String }
    static targets = ['btn']

    connect() {
        let payload = JSON.parse(this.payloadValue)
        let editmode = JSON.parse(this.editmodeValue)

        window.onload = async () => {
            const modalData = JSON.parse(payload.modalData).length > 0 ? JSON.parse(payload.modalData) : []
            const svgModalKey = payload.svgModalKey

            this.overrides = $('[data-name="' + svgModalKey + '"]')
            this.list = this.overrides.text() !== '' ? JSON.parse(this.overrides.text()) : []
            const container = document.getElementById('svgContainer')

            let steps = [
                {
                    title: payload.intro_title,
                    content: payload.intro_text,
                },
            ]
            this.textContainer = $('#textContainer')
            this.svgDiv = $('#svgContainer')

            if (!editmode) {
                if (modalData.length > 0) {
                    modalData.map(items => {
                        steps.push({
                            element: '#' + items.id,
                            title: items.title,
                            content: items.description,
                            placement: 'top-start',
                        })
                        this.svgDiv.append('<div id="' + items.id + '" class="bg-transparent"></div>')
                        $('#' + items.id).css('top', items.top - 1.5 + 'px')
                            .css('left', items.left - 1.5 + 'px')
                            .css('position', 'absolute')
                            .css('width', items.width + 'px')
                            .css('height', items.height + 'px')
                    })
                }
            } else {
                if (this.list.length > 0) {
                    renderTour(this.list, this.svgDiv, this.textContainer)
                    $('.modals').hide()
                }
            }
            if (editmode) {
                container.addEventListener('mouseup', (event) => {
                    const selection = window.getSelection()
                    if (selection && selection.rangeCount > 0) {

                        const selectedText = selection.toString()
                        const range = selection.getRangeAt(0)
                        const rect = range.getBoundingClientRect()
                        const refRect = container.getBoundingClientRect()

                        const parentID = range.commonAncestorContainer.parentElement.id
                        let targetTourId = null
                        if (parentID) {
                            targetTourId = parentID + '-' + Math.floor(Math.random() * 99999)
                        } else {
                            targetTourId = 'target-tour-' + Math.floor(Math.random() * 99999)
                        }

                        const offsetX = rect.left - refRect.left
                        const offsetY = rect.top - refRect.top

                        if (selectedText.trim()) {
                            $('.modals').hide()

                            this.svgDiv.append('<button id="open_' + targetTourId + '" data-action="web-tour#open" class="bg-primary bg-opacity-10"></button>')
                            $('#open_' + targetTourId).css('top', offsetY - 1.5 + 'px')
                                .css('left', offsetX - 1.5 + 'px')
                                .css('position', 'absolute')
                                .css('width', rect.width + 4 + 'px')
                                .css('height', rect.height + 3 + 'px')
                                .css('border', '0.5px solid #003273')
                            this.textContainer.append(renderModal(targetTourId))

                            $('#modal-' + targetTourId).css('top', offsetY + rect.height + 25 + 'px')

                            this.list.push({
                                'id': targetTourId,
                                'top': offsetY,
                                'left': offsetX,
                                'width': rect.width,
                                'height': rect.height,
                                'title': '',
                                'description': '',
                            })
                            this.overrides.text(JSON.stringify(this.list))
                            selection.removeAllRanges()
                        }
                    }
                })
            }
            try {
                await loadCSS('/static/js/webtour/webtour.min.css')
                await loadJS('/static/js/webtour/webtour.js')
            } catch (error) {
                console.error(error)
            }

            let wt = new WebTour()

            wt.options = {
                animate: true,
                opacity: 0.5,
                offset: 20,
                borderRadius: 3,
                allowClose: false,
                highlight: true,
                highlightOffset: 5,
                keyboard: true,
                width: '500px',
                zIndex: 10050,
                removeArrow: false,
            }

            wt.setSteps(steps)

            if (!editmode) {
                await wt.start()
            }

            // Watch for hash changes
            document.addEventListener('hashchange', () => {
                const hash = window.location.hash
                let sectionId = hash.split('#')
                document.getElementById(sectionId[1]).scrollIntoView({ behavior: 'smooth' })
            })
        }
    }

    open(event) {
        console.log(event.target.id)
        $('.modals').hide()
        const modalId = event.target.id.split('open_')[1]
        $('#modal-' + modalId).show()
    }

    close(event) {
        const modalId = event.target.id.split('close_')[1]
        $('#modal-' + modalId).hide()
    }

    updatetitle(event) {
        const id = event.target.id.split('overrideTitle_')[1]
        if (this.list !== undefined && this.list.length > 0) {
            this.list.find(element => {
                if (element.id === id) {
                    element.title = event.target.value
                }
            })
            this.overrides.text(JSON.stringify(this.list))
        }
    }

    updatedescription(event) {
        const id = event.target.id.split('overrideDescription_')[1]
        if (this.list !== undefined && this.list.length > 0) {
            this.list.find(element => {
                if (element.id === id) {
                    element.description = event.target.value
                }
            })
            this.overrides.text(JSON.stringify(this.list))
        }
    }

    delete(event) {
        const id = event.target.id.split('delete_')[1]
        console.log(event.target.id)
        if (this.list !== undefined && this.list.length > 0) {
            let index = this.list.findIndex(element => element.id === id)
            if (index !== -1) {
                this.list.splice(index, 1)
            }
            this.overrides.text(JSON.stringify(this.list))
            $('#open_' + id).remove()
            $('#modal-' + id).remove()
            // renderTour(this.list, this.svgDiv, this.textContainer)
        }
    }
}

// Funktion zum Laden einer CSS-Datei
function loadCSS(href) {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = href
        link.onload = () => resolve(`CSS geladen: ${href}`)
        link.onerror = () => reject(`Fehler beim Laden der CSS-Datei: ${href}`)
        document.head.appendChild(link)
    })
}

// Funktion zum Laden einer JS-Datei
function loadJS(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.onload = () => resolve(`JS geladen: ${src}`)
        script.onerror = () => reject(`Fehler beim Laden der JS-Datei: ${src}`)
        document.body.appendChild(script)
    })
}

function renderTour(list, svgDiv, textContainer) {
    list.map(items => {
        svgDiv.append('<button id="open_' + items.id + '" data-action="web-tour#open" class="bg-primary bg-opacity-10"></button>')
        $('#open_' + items.id).css('top', items.top - 1.5 + 'px')
            .css('left', items.left - 1.5 + 'px')
            .css('position', 'absolute')
            .css('width', items.width + 4 + 'px')
            .css('height', items.height + 3 + 'px')
            .css('border', '0.5px solid #003273')
        textContainer.append('<div id="modal-' + items.id + '"\n' +
            '                     class="modals position-absolute start-50 translate-middle-x mw-lg-3xl w-100 min-vh-20 mx-auto position-relative bg-white shadow drop-shadow-sm px-4 py-5 "\n' +
            '                     style="z-index: 9999999;">\n' +
            '                    <div\n' +
            '                        class="position-absolute top-0 end-0 d-flex align-items-center justify-content-end gap-2 pt-2 pe-2">\n' +
            '                        <button id="delete_' + items.id + '" data-action="web-tour#delete" data-web-tour-id-value="' + items.id + '" class="cursor-pointer fs-9 fw-medium border" style="border-color: rgba(139,0,0,0.5);background-color: rgba(139,0,0,0.1)">Löschen</button>\n' +
            '                        <button id="close_' + items.id + '" data-action="web-tour#close" data-web-tour-id-value="' + items.id + '" class="cursor-pointer fs-9 fw-medium bg-primary text-white border border-primary">Schließen</button>\n' +
            '                    </div>\n' +
            '                    <div class="w-100 gap-2 d-flex flex-row justify-content-start">\n' +
            '                        <label class="w-10 text-primary" for="overrideTitle-' + items.id + '">Titel:</label>\n' +
            '                        <input data-action="input->web-tour#updatetitle" id="overrideTitle_' + items.id + '" type="text" name="override-title-' + items.id + '" value="' + items.title + '">\n' +
            '                    </div>\n' +
            '                    <div class="w-100 d-flex flex-column justify-content-start">\n' +
            '                        <label class="w-100 text-primary" for="overrideDescription-' + items.id + '">Beschreibung:</label>\n' +
            '                       <textarea data-action="input->web-tour#updatedescription" name="override-description-' + items.id + '" class="p-3" id="overrideDescription_' + items.id + '" cols="30" rows="10">' + items.description + '</textarea>\n' +
            '                    </div>\n' +
            '                </div>')
        $('#modal-' + items.id).css('top', items.top + items.height + 65 + 'px')
    })
}

function renderModal(id) {
    return '<div style="z-index: 999999;" id="modal-' + id + '"\n' +
        '                     class="modals position-absolute start-50 translate-middle-x mw-lg-3xl w-100 min-vh-20 mx-auto position-relative bg-white shadow drop-shadow-sm px-4 py-5">\n' +
        '                    <div\n' +
        '                        class="position-absolute top-0 end-0 d-flex align-items-center justify-content-end gap-2 pt-2 pe-2">\n' +
        '                        <button id="delete_' + id + '" data-action="web-tour#delete" data-web-tour-id-value="' + id + '" class="cursor-pointer fs-9 fw-medium border" style="border-color: rgba(139,0,0,0.5) !important; background-color: rgba(139,0,0,0.1)">Löschen</button>\n' +
        '                        <button id="close_' + id + '" data-action="web-tour#close" data-web-tour-id-value="' + id + '" class="cursor-pointer fs-9 fw-medium bg-primary text-white border border-primary">Schließen</button>\n' +
        '                    </div>\n' +
        '                    <div class="w-100 gap-2 d-flex flex-row justify-content-start">\n' +
        '                        <label class="w-10 text-primary" for="overrideTitle-' + id + '">Titel:</label>\n' +
        '                        <input data-action="input->web-tour#updatetitle" id="overrideTitle_' + id + '" type="text" name="override-title-' + id + '">\n' +
        '                    </div>\n' +
        '                    <div class="w-100 d-flex flex-column justify-content-start">\n' +
        '                        <label class="w-100 text-primary" for="overrideDescription-' + id + '">Beschreibung:</label>\n' +
        '                        <textarea data-action="input->web-tour#updatedescription" name="override-description-' + id + '" class="p-3" id="overrideDescription_' + id + '" cols="30" rows="10"></textarea>\n' +
        '                    </div>\n' +
        '                </div>'
}

