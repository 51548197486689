import {Controller} from '@hotwired/stimulus'
import $ from 'jquery'


export default class extends Controller {
    static values = {slides: String}

    connect() {
        let slides_arr = JSON.parse(this.slidesValue)
        let app = $('#app')
        const currLoc = window.location.origin
        requestHandler(slides_arr)

        $(document).ready(function () {
            $('#app .carousel-item:first-child').addClass('active')
            $('#slideButtonContainer').show().removeClass('d-none').addClass('d-flex')

            if ($('.active').index() === 0) {
                $('#btnPrevious').addClass('d-none').removeClass('d-block')
                $('#BactToStart').addClass('d-none').removeClass('d-block')
            } else {
                $('#btnPrevious').addClass('d-block').removeClass('d-none')
            }

            let child = slides_arr.length - 1
            if ($('.active').index() === child) {
                $('#btnNext').addClass('d-none')
            }

            let slide = slides_arr[$('.active').index()]
            checkIconLinks(slide)

            $('#BactToStart').on('click', function () {
                $('#btnStart').show()
                $('#app .carousel-item').removeClass('active')
                $('#app .carousel-item:first-child').addClass('active')
                console.log($('.active').index())
                if ($('.active').index() === 0) {
                    $('#BactToStart').addClass('d-none').removeClass('d-block')
                    $('#btnNext').addClass('d-block').removeClass('d-none')
                    $('#btnPrevious').addClass('d-none').removeClass('d-block')
                }
                $('#SlideLinks .icons-links').remove()
                let slide = slides_arr[$('.active').index()]
                checkIconLinks(slide)
            })

            $('#btnNext').click(function () {
                if ($('.active').index() === 0) {
                    $('#BactToStart').addClass('d-block').removeClass('d-none')
                }

                if ($('.active').index() + 1 === 1) {
                    $('#btnPrevious').addClass('d-none')
                } else {
                    $('#btnPrevious').removeClass('d-none').addClass('d-block')
                }

                let child = slides_arr.length - 1

                if ($('.active').index() + 1 === child) {
                    $('#btnNext').addClass('d-none')
                } else {
                    $('#btnNext').removeClass('d-none').addClass('d-block')
                }

                let slide = slides_arr[$('.active').index() + 1]
                checkIconLinks(slide)
            })

            $('#btnPrevious').click(function () {
                if ($('.active').index() - 1 === 0) {
                    $('#BactToStart').addClass('d-block').removeClass('d-none')
                }
                if ($('.active').index() - 1 === 1) {
                    $('#btnPrevious').addClass('d-none').removeClass('d-block')
                } else {
                    $('#btnPrevious').removeClass('d-none').addClass('d-block')
                }

                let child = slides_arr.length - 1

                if ($('.active').index() - 1 === child) {
                    $('#btnNext').addClass('d-none')
                } else {
                    $('#btnNext').removeClass('d-none').addClass('d-block')
                }
                let slide = slides_arr[$('.active').index() - 1]
                checkIconLinks(slide)
            })
        })

        function requestHandler(slideList) {
            $('.slides').remove() // remove all slides from template
            $('.slide-btn').remove() // remove all indicators from template

            if (slideList.length) {
                $.each(slideList, function (key, val) {
                    let d_flex = ''
                    let text_pos = ''
                    if (val.content_position === 'left' || val.content_position === '') {
                        d_flex = ''
                        text_pos = 'align-items-start'
                    } else if (val.content_position === 'right') {
                        d_flex = 'flex-wrap flex-row-reverse'
                        text_pos = 'align-items-end'
                    }
                    app.append(loadSlideContent(key, val, d_flex, text_pos))
                })
            }
        }

        function loadSlideContent(key, value, contentPos, text_pos) {
            let content = ''
            let description_width = ''
            let title_width = ''
            if (value.description_width) {
                description_width = value.description_width
            } else {
                description_width = 'col-12 col-md-8 col-lg-4'
            }
            if (value.title_width) {
                title_width = value.title_width
            } else {
                title_width = 'col-12 col-md-10 col-lg-7'
            }
            // ' + text_pos + '
            if (value.template === 'slides\\index.html.twig') {
                content += '<div class="carousel-item slides w-100" style="position: unset !important;">'
                content += '<div class="min-vh-65 min-vh-lg-70 min-vh-xl-75 h-100 row position-relative ' + contentPos + '">'
                content += '<div class="bg-white rounded col-12 col-lg-5 p-4 p-lg-5 h-auto d-flex flex-column justify-content-start shadow drop-shadow position-relative" style="gap: 20px; z-index: 2;">'
                if (value.title) {
                    content += '<h1 class="fs-3 fs-lg-2 fw-bold text-primary animate__animated animate__fadeInUp animate__delay-1s">' + value.title + '</h1>'
                }
                if (value.description) {
                    content += '<div class="mt-5 fs-5 animate__animated animate__fadeInUp animate__delay-2s">' + value.description + '</div>'
                }
                if (value.side_photo) {
                    content += '<div class="position-relative mt-10" style="z-index: 0">'
                    content += '<img src="' + currLoc + value.side_photo + '" class=" w-100 h-100 animate__animated animate__fadeInUp animate__delay-2s" style="z-index: 0;" alt="">'
                    content += '</div>'
                }
                content += '</div>'
                content += '<div class="col-12 col-lg-7 h-auto py-3 ps-4 pe-3 position-relative d-flex align-items-center justify-content-center" style="z-index: 0;">'
                if (value.slide_img) {
                    content += '<img src="' + currLoc + value.slide_img + '" class="w-100 position-relative rounded shadow-sm drop-shadow-sm animate__animated animate__fadeIn" alt="">'
                    //  content += '<img src="' + currLoc + value.slide_img + '" class=" w-100 h-100 position-absolute top-0 end-0 animate__animated animate__fadeIn" style="z-index: 0; object-fit: cover; background-size: cover; object-position: center" alt="">'
                }
                content += '</div>'
                content += '</div>'
                content += '</div>'
            }

            if (value.template === "slides\\fullPhoto.html.twig") {

                let text_position = '';
                if (text_pos ==="align-items-end"){
                    text_position = "text-end"
                }

                content += '<div class="carousel-item slides w-100 min-vh-65 min-vh-lg-70 min-vh-xl-75 h-100">'
                content += '<div class="row ' + contentPos + '">'

                content += '<div class="w-100 p-4 p-lg-5 min-vh-lg-75 d-flex flex-column justify-content-start ' + text_pos + '" style="gap: 20px; z-index: 2;">'
                if (value.title) {
                    content += '<h1 class="' + title_width + ' ' + text_position + ' fs-3 fs-lg-2 px-3 px-lg-5 py-2 text-primary rounded-1 bg-light bg-opacity-75 display-5 fw-bold animate__animated animate__fadeInUp animate__delay-1s">' + value.title + '</h1>'
                }

                if (value.description) {
                    content += '<div class="' + description_width + ' ' + text_position + ' mt-5 p-3 p-lg-5 fs-5 rounded-1 bg-light bg-opacity-75 animate__animated animate__fadeInUp animate__delay-2s" style="z-index: 3">' + value.description + '</div>'
                }
                if (value.full_photo) {
                    content += '<div class="w-80 mx-auto position-absolute bottom-0 start-50 translate-middle-x" style="z-index: 0">'

                    content += '<img src="' + currLoc + value.full_photo + '" class=" w-100 h-100 animate__animated animate__fadeIn" style="z-index: 0;" alt="">'

                    content += '</div>'
                }

                content += '</div>'


                content += '<div class="w-100 h-100 position-absolute top-0 start-0 bg-white" style="z-index: 0;">'
                if (value.slide_img) {
                    content += '<img src="' + currLoc + value.slide_img + '" class=" w-100 h-100 position-absolute top-0 end-0 animate__animated animate__fadeIn" style="z-index: 0; object-fit: cover; object-position: center" alt="">'
                }
                content += '</div>'


                content += '</div>'
                content += '</div>'
            }

            if (value && value.template === 'slides\\cards.html.twig') {

                content += '<div class="carousel-item slides w-100 " style="position: unset !important;">'
                content += '<div class="min-vh-65 min-vh-lg-70 min-vh-xl-75 h-100 row ' + contentPos + '">'
                content += '<div class="w-100 h-100 position-absolute top-0 start-0 bg-white" style="z-index: 0;">'
                if (value.slide_img) {
                    content += '<img src="' + currLoc + value.slide_img + '" class=" w-100 h-100 position-absolute top-0 end-0 animate__animated animate__fadeIn" style="z-index: 0; object-fit: cover; object-position: center" alt="">'
                }
                content += '</div>'
                content += '<div class="w-100 p-4 p-lg-5 position-relative d-flex flex-column justify-content-between" style="gap: 20px; z-index: 2;">'

                if (value.title) {
                    content += '<h1 class="w-100 fs-3 fs-lg-2 px-3 bg-white bg-opacity-75 fw-bold text-center text-primary animate__animated animate__fadeInUp animate__delay-1s">' + value.title + '</h1>'
                }
                if (Object.hasOwn(value, 'card_items') && value.card_items !== 'undefined' && value.card_items.length) {
                    if (value.card_version === 'card-6') {
                        content += '<div class="w-100 px-3 row row-cols-3 justify-content-center">'
                        value.card_items.map(element => {
                            if (element) {
                                content += '<div class="mw-sm mb-2 animate__animated animate__fadeInUp animate__delay-2s">'
                                content += '<div class="mw-xs mx-auto position-relative text-center">'
                                content += '<div class="w-100 bg-light position-relative d-flex overflow-hidden m-0 p-0 rounded-2 shadow drop-shadow-sm" style="max-height: 20vh;">'
                                if (element.card_img) {
                                    content += '<img src="' + currLoc + element.card_img + '" alt="" title="" class="w-100 h-100 position-relative top-0 end-0 " style="z-index: 0; object-fit: cover; object-position: center">'
                                }
                                content += '</div>'
                                content += '<div class="px-0 py-2">'
                                if (element.card_title) {
                                    content += '<div class="mt-1 mb-2">'
                                    content += '<h3 class="text-primary bg-white bg-opacity-75">' + element.card_title + '</h3>'
                                    content += '</div>'
                                }
                                content += '</div>'
                                content += '</div>'
                                content += '</div>'
                            }
                        })
                        content += '</div>'
                    }
                    if (value.card_version === 'card-4') {
                        content += '<div class="w-100 px-3 row row-cols-2 justify-content-center">'
                        value.card_items.map((items, i) => {
                            content += '<div class="col-6 row flex-column align-items-center position-relative">'
                            if (items.card_uberschrift) {
                                content += '<div class="verticaltext_content text-cta-primary">' + items.card_uberschrift + '</div>'
                            }
                            if (items.cards.length) {
                                items.cards.map((element, n) => {
                                    content += '<div class="mw-sm mb-2 animate__animated animate__fadeInUp animate__delay-2s">'
                                    content += '<div class="mw-xs mx-auto position-relative text-center">'
                                    content += '<div class="w-100 bg-light position-relative d-flex overflow-hidden m-0 p-0 rounded-2 shadow drop-shadow-sm" style="max-height: 20vh;">'
                                    if (element.card_img) {
                                        content += '<img src="' + currLoc + element.card_img + '" alt="" title="" class="w-100 h-100 position-relative top-0 end-0 " style="z-index: 0; object-fit: cover; object-position: center">'
                                    }
                                    content += '</div>'
                                    content += '<div class="px-0 py-2">'
                                    if (element.card_title) {
                                        content += '<div class="mt-1 mb-2">'
                                        content += '<h3 class="text-primary">' + element.card_title + '</h3>'
                                        content += '</div>'
                                    }
                                    content += '</div>'
                                    content += '</div>'
                                    content += '</div>'
                                })
                            }
                            content += '</div>'

                        })
                        content += '</div>'
                    }
                }
                if (value.description) {
                    content += '<div class="w-100 text-center mt-2 px-3 animate__animated animate__fadeInUp animate__delay-3s">' + value.description + '</div>'
                }

                content += '</div>'
                content += '</div>'
                content += '</div>'
            }

            return content
        }


        function checkIconLinks(slides) {
            let div = ''
            $('#SlideLinks .icons-links').remove()
            if (Object.hasOwn(slides, 'icons_items') && slides.icons_items !== 'undefined' && slides.icons_items.length) {
                div = ''
                if (slides.icons_items.length) {
                    div += '<div class="icons-links w-auto py-2 px-4 ms-10 d-flex justify-content-end align-items-cente position-absolute top-0 end-0 rounded-bottom-1" style="gap: 16px; background-color: #434c5b; z-index: 10;">'
                    slides.icons_items.map(item => {
                        console.log('item', item)
                        div += '<div class="d-flex flex-column align-items-center gap-2">'
                        div += '<a href="' + item.link.href + '" target="_blank" class="w-9 h-9 border-0 d-flex bg-nav-link-color bg-opacity-50 rounded-2 align-items-center justify-content-center">'
                        div += '<i class="bi ' + item.icon + ' fs-5 text-cta-primary" style="line-height: 0;"></i>'
                        div += '</a>'
                        div += '<div class="fs-7 text-cta-primary mt-1 pb-2" style="line-height: 0;">' + item.link.title + '</div>'
                        div += '</div>'
                    })

                    div += '</div>'
                    $('#SlideLinks').append(div)
                }
            } else {
                div = ''
                $('#SlideLinks .icons-links').remove()
            }
        }
    }
}