import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'


export default class extends Controller {
    connect() {
        $(document).ready(function() {
            // active class
            let url = window.location
            $('ul.nav a[href="' + url + '"]').addClass('active')
            $('.nav-item a').filter(function() {
                return this.href === url.href
            }).addClass('active')

            let topOfWindow = $(window).scrollTop()
            onWindowScroll(topOfWindow)
            // on scroll
            $(window).scroll(function() {
                topOfWindow = $(window).scrollTop()
                onWindowScroll(topOfWindow)
            })

            $('#sidebar-btn').on('click', function() {
                $('#sidebar').toggleClass('visible')
                $('#sidebarOverlay').toggleClass('visible')
                let bar2 = $('#sidebar-btn .bar-2').hasClass('w-50')
                let bar3 = $('#sidebar-btn .bar-3').hasClass('w-75')
                if (bar2) {
                    $('#sidebar-btn .bar-2').removeClass('w-50').addClass('w-90')
                } else {
                    $('#sidebar-btn .bar-2').removeClass('w-90').addClass('w-50')
                }
                if (bar3) {
                    $('#sidebar-btn .bar-3').removeClass('w-90').addClass('w-50')
                } else {
                    $('#sidebar-btn .bar-3').removeClass('w-50').addClass('w-90')
                }
            })

            $('#sidebarOverlay').on('click', function() {
                $('#sidebar').toggleClass('visible')
                $('#sidebarOverlay').toggleClass('visible')
                let bar2 = $('#sidebar-btn .bar-2').hasClass('w-50')
                let bar3 = $('#sidebar-btn .bar-3').hasClass('w-75')
                if (bar2) {
                    $('#sidebar-btn .bar-2').removeClass('w-50').addClass('w-90')
                } else {
                    $('#sidebar-btn .bar-2').removeClass('w-90').addClass('w-50')
                }
                if (bar3) {
                    $('#sidebar-btn .bar-3').removeClass('w-90').addClass('w-50')
                } else {
                    $('#sidebar-btn .bar-3').removeClass('w-50').addClass('w-90')
                }
            })


            $('.drop-down > .answer').hide()

            $('.menu-toggle').on('click', function() {
                let dataKey = $(this).data('key')
                if ($(this).next('.drop-down').hasClass('active')) {
                    $(this).next('.drop-down').removeClass('active').find('.answer').slideUp()
                    $(this).css('content', 'url("/idam2.0/svg/0827-chevron-down/4850?fill=ffffff")')
                } else {
                    $(this).next('.drop-down.active').removeClass('active')
                    $(this).next('.drop-down').addClass('active')
                    $(this).next('.drop-down.active').find('#answer_' + dataKey).slideDown()
                    $(this).css('content', 'url("/idam2.0/svg/0826-chevron-up/4849?fill=ffffff")')
                }
                return false
            })
        })

        function onWindowScroll(topOfWindow) {
            const topNav = $('#TopNavigation')
            if (topOfWindow > 80) {
                topNav.removeClass('h-13').css('transition', 'all .15s ease')
                $('#TopNavigation .navbar.navbar-expand-lg').removeClass('py-1').addClass('py-0')
                topNav.removeClass('bg-transparent').css('transition', 'all .15s ease').addClass('bg-white')
                topNav.addClass('shadow-sm drop-shadow-xs')
                $('.navbar-brand img').css('max-height', '38px').css('transition', 'all .15s ease')
                $('.nav-link').addClass('fs-7').removeClass('fs-6').css('transition', 'all .15s ease')
            } else {
                topNav.removeClass('h-13').css('transition', 'all .15s ease')
                topNav.addClass('h-13').css('transition', 'all .15s ease')
                $('#TopNavigation .navbar.navbar-expand-lg').addClass('py-1').removeClass('py-0')
                topNav.removeClass('shadow-sm drop-shadow-xs')
                $('.navbar-brand img').css('max-height', '45px').css('transition', 'all .15s ease')
                $('.nav-link').removeClass('fs-7').addClass('fs-6')
            }
        }


    }
}