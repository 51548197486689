import '@symfony/ux-live-component/dist/live.min.css';
import { Controller } from '@hotwired/stimulus';
export default {
  'live': class extends Controller {
      constructor(context) {
          super(context);
          this.__stimulusLazyController = true;
      }
      initialize() {
          if (this.application.controllers.find((controller) => {
              return controller.identifier === this.identifier && controller.__stimulusLazyController;
          })) {
              return;
          }
          import('@symfony/ux-live-component/dist/live_controller.js').then((controller) => {
              this.application.register(this.identifier, controller.default);
          });
      }
  },
};