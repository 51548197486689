import { Controller } from '@hotwired/stimulus'
// import $ from 'jquery'

export default class extends Controller {
    static classes = ['active']
    static targets = ['btn', 'tab', 'line', 'icon']
    static values = { defaultTab: String }

    connect() {
        // hide all tabs
        this.tabTargets.map(x => x.hidden = true)
        this.lineTargets.map(x => x.hidden = true)

        //  show the default tab
        let selectedTab = this.tabTargets.find(element => element.id === this.defaultTabValue)
        selectedTab.hidden = false
        //  show the default line
        let selectedLine = this.lineTargets.find(element => element.id === this.defaultTabValue)
        selectedLine.hidden = false
        // activate the selected button
        let selectedBtn = this.btnTargets.find(element => element.id === this.defaultTabValue)
        selectedBtn.classList.add(...this.activeClasses)
    }

    // switch between tabs
    select(event) {
        // find tab matching (with same id as) the clicked btn
        let selectedTab = this.tabTargets.find(element => element.id === event.currentTarget.id)
        if (selectedTab.hidden) {
            // hide everything
            this.tabTargets.map(x => x.hidden = true) // hide all tabs
            this.btnTargets.map(x => x.classList.remove(...this.activeClasses)) // deactive all btns

            // show selected
            selectedTab.hidden = false // show current tab
            event.currentTarget.classList.add(...this.activeClasses) // activate current button
        }

        let selectedLine = this.lineTargets.find(element => element.id === event.currentTarget.id)
        if (selectedLine.hidden) {
            // hide everything
            this.lineTargets.map(x => x.hidden = true) // hide all tabs

            // show selected
            selectedLine.hidden = false // show current tab
        }
    }
}