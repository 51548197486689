import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'


export default class extends Controller {
    connect() {
        const factRings = document.querySelectorAll('.fact_rings')

        const observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    detected()
                } else {
                    $('.fact_rings').each(function() {
                        $(this).removeClass('open-animation')
                    })
                }
            })
        }, {
            root: null,
            threshold: 0, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })

        function detected() {
            let duaration = 1.5
            $('.fact_rings').each(function() {
                $(this).addClass('open-animation')
                $(this).css('animation-duration', duaration + 's')
                duaration += 1
            })
        }

        factRings.forEach(function(i) {
            observer.observe(i)
        })
    }
}