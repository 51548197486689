import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'


export default class extends Controller {
    connect() {
        const el = document.querySelector('.facts')

        function startFactsCount() {
            let counter = $('.counter')

            counter.each(function() {

                if (!isNaN(Math.ceil($(this).text()))) {
                    $(this).prop('Counter', 0).animate({
                        Counter: $(this).text(),
                    }, {
                        duration: 3000,
                        easing: 'swing',
                        step: function(now) {
                            $(this).text(Math.ceil(now))
                        },
                    })
                }
            })
        }

        const observer = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                startFactsCount()
            }
        }, {
            root: null,
            threshold: 0, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })


        observer.observe(el)
    }
}