import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'


export default class extends Controller {
    connect() {
        let listItem = $('#listItem')
        console.log(listItem.attr('style'))
        if ($(window).width() <= '480') {
            listItem.attr('style', '--g-cols-max: 1fr')
        } else {
            listItem.attr('style', '--g-cols-max: 214px')
        }
    }
}